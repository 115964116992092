import React, { useEffect } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Donut from 'assets/svg/donut.svg';
import Noodle from 'assets/svg/noodle.svg';
import Truck from 'assets/svg/truck.svg';
import Arrow from 'assets/svg/arrow-right.svg';
import Camper from 'assets/svg/camper.svg';

import Layout from 'components/layout';
import SEO from 'components/SEO';

import { homeIntro } from '../animations/homeAnimations';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      homeMobileOne: file(relativePath: { eq: "home_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeDesktopOne: file(relativePath: { eq: "home_02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeMobileTwo: file(relativePath: { eq: "home_03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeDesktopTwo: file(relativePath: { eq: "home_03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeMobileThree: file(relativePath: { eq: "home_04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      homeDesktopThree: file(relativePath: { eq: "home_04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phan: file(relativePath: { eq: "phan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vorfor: file(relativePath: { eq: "vorfor.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const homeImageOne = [
    data.homeMobileOne.childImageSharp.fluid,
    {
      ...data.homeDesktopOne.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const homeImageTwo = [
    data.homeMobileTwo.childImageSharp.fluid,
    {
      ...data.homeDesktopTwo.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const homeImageThree = [
    data.homeMobileThree.childImageSharp.fluid,
    {
      ...data.homeDesktopThree.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  useEffect(() => {
    homeIntro();
  }, []);

  return (
    <Layout>
      <SEO title="Velkommen til Phans Food" />
      <div>
        <div className="flex flex-col md:flex-row justify-between px-6 md:px-16 mb-16">
          <AniLink
            cover
            direction="right"
            bg="#d2401e"
            className="group flex flex-col md:w-col-2/4 mb-8 md:mb-0"
            activeClassName=""
            to="mr-phan-house"
            key="mr-phan-house"
          >
            <div className="h-screen-1/4 md:h-screen-1/2 mb-4 overflow-hidden">
              <Img
                fluid={homeImageOne}
                className="w-full h-full object-cover transform transition-transform duration-300 ease-out group-hover:scale-110 rounded"
              />
            </div>
            <div className="flex items-center text-center md:text-left">
              <Noodle className="w-12 mr-4 fill-current text-phan" />
              <span>
                <h2 className="font-semibold text-phan">
                  Mr. Phan House Vietnamese Food
                </h2>
              </span>
              <Arrow className="w-8 ml-auto stroke-current transition-all duration-150 ease-in-out text-gray-500 group-hover:text-gray-900 transform group-hover:-translate-x-5" />
            </div>
          </AniLink>

          <AniLink
            cover
            direction="right"
            bg="#dc976d"
            className="group flex flex-col md:w-col-2/4 mb-8 md:mb-0"
            activeClassName=""
            to="vorfor-vaffel"
            key="vorfor-vaffel"
          >
            <div className="h-screen-1/4 md:h-screen-1/2 mb-4 overflow-hidden">
              <Img
                fluid={homeImageTwo}
                className="w-full h-full object-cover transform transition-transform duration-300 ease-out group-hover:scale-110 rounded"
              />
            </div>
            <div className="flex items-center text-center md:text-left">
              <Donut className="w-12 mr-4 fill-current text-waffle" />
              <span>
                <h2 className="font-semibold text-waffle">
                  Vorfor Vafler & Bakery
                </h2>
              </span>
              <Arrow className="w-8 ml-auto stroke-current transition-all duration-150 ease-in-out text-gray-500 group-hover:text-gray-900 transform group-hover:-translate-x-5" />
            </div>
          </AniLink>
        </div>

        {/* <div className="flex flex-col md:flex-row justify-between px-6 md:px-16 mb-8">
          <AniLink
            cover
            direction="right"
            bg="#2d3748"
            className="group flex flex-col md:w-col-2/4 mb-8 md:mb-0"
            activeClassName=""
            to="mr-phan-house-truck"
            key="mr-phan-house-truck"
          >
            <div className="h-screen-1/4 md:h-screen-1/2 mb-4 overflow-hidden">
              <Img
                fluid={homeImageThree}
                className="w-full h-full object-cover transform transition-transform duration-300 ease-out group-hover:scale-110 rounded"
              />
            </div>
            <div className="flex items-center text-center md:text-left">
              <Truck className="w-10 mr-4 fill-current text-gray-800" />
              <span>
                <h2 className="font-semibold text-gray-800">
                  Mr. Phan House Truck
                </h2>
                <p className="text-sm text-gray-600">Kan bookes til event</p>
              </span>
              <Arrow className="w-8 ml-auto stroke-current transition-all duration-150 ease-in-out text-gray-500 group-hover:text-gray-900 transform group-hover:-translate-x-5" />
            </div>
          </AniLink>
        </div> */}
      </div>
    </Layout>
  );
};

export default IndexPage;
