import gsap, { Power3 } from 'gsap';

let tl = gsap.timeline();

export const homeIntro = () => {
  tl.staggerFrom(
    [],
    1,
    {
      y: 44,
      ease: Power3.easeOut,
      delay: 0.8,
    },
    0.15,
    'Start'
  );
  // .from(contentP, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.4)
  // .from(contentButton, 1, { y: 20, opacity: 0, ease: Power3.easeOut }, 1.6);
};
